import {capitalize} from 'lodash';

/**
 * @name startCase
 * @description Capitalizes all words in a sentence (doesn't strip non
 *              alphanumerics like Lodash's startCase)
 *
 */
export const startCase = (string: string): string => {
  if (typeof string !== 'string') {
    return '';
  }

  return string
    .replace(/[-_]/g, ' ')
    .split(' ')
    .map(chunk => capitalize(chunk))
    .join(' ');
};
